import React from "react";
import Step1 from "../../../assetsC/images/aled.png";
import Step2 from "../../../assetsC/images/sarim.png";
import Step3 from "../../../assetsC/images/siddiqui.png";

const data = [
  {
    name: "James Davis",
    description: "Creative Director - SocialSphere Solutions",
    quote:
      " Spread is a game-changer for managing posts effortlessly! The scheduling feature saves time and keeps my strategy organized. Creating drafts and receiving top posts every hour keeps me up-to-date. The AI-generated posts make it easy to create engaging content. Installing the Spread Chrome extension is a must for X post management!",
    avatar: Step1,
  },
  {
    name: "Brian Wilson",
    description: "Brand Ambassador - MediaLink Pro",
    quote:
      " Spread is the ultimate solution for X management! Scheduling posts and creating drafts is a breeze. The top posts feature keeps me informed, and AI-generated posts provide relevant content. Streamline your posts with the Spread Chrome extension!",
    avatar: Step2,
  },
  {
    name: "Kevin Lee",
    description: "Content Creator - BuzzSync Media",
    quote:
      " Spread simplifies X like never before. Scheduling posts and creating drafts is seamless. The top posts feature keeps me ahead of the competition, and AI-generated posts provide fresh content ideas. Upgrade your tweet strategy with the Spread Chrome extension!",
    avatar: Step3,
  },
];

const AboutFooter = () => {
  return (
    <div className="about-footer">
      <div className="container users-quote">
        <h1>What our users say about us</h1>
        <div className="project-snap-row">
          <div className="row align-stretch">
            {data.map((element, index) => {
              return (
                <div className="col-lg-4 col-sm-12" key={index}>
                  <div className="project-snap-block">
                    <div className="testimonial">
                      <div className="aboutFooterText">
                        <img
                          src={element.avatar}
                          className="avatar"
                          alt={""}
                        ></img>

                        <div className="aboutFooterTextP">
                          <p className="user-name">{element.name}</p>
                          <p className="user-title">{element.description}</p>
                        </div>
                      </div>
                      <p className="quote">{element.quote}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFooter;
