import React from "react";
import Step1 from "../../../assetsC/images/Step1.png";
import Step2 from "../../../assetsC/images/Step2.png";
import Step3 from "../../../assetsC/images/draft-ss.png";

const AboutWork = ({ scrollDiv }) => {
  return (
    <div className="how-it-works" ref={scrollDiv}>
      <div className="container .fade.visible">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              <p className="desc-2">AI suggested posts</p>
              <p className="desc-3">
                Instant recommendations are generated for you based on activity
                by AI power.
              </p>
            </div>
            <div className="project-image-holder">
              <img
                src={Step1}
                className="img-fluid rounded mx-auto d-block"
                alt="Project"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              {/* <p className="desc-1">know your team</p> */}
              <p className="desc-2">Schedule your posts in advance</p>
              <p className="desc-3">
                The spread extension can be leveraged effectively for scheduling
                X posts.
              </p>
            </div>

            <div className="project-image-holder">
              <img
                src={Step2}
                alt="Project"
                className="img-fluid rounded mx-auto d-block"
              />
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
            
              <p className="desc-2">Topic Management</p>
              <p className="desc-3">
             Users can create topics & retrieve prominent posts related with those topics.
              </p>
            </div>

            <div className="project-image-holder">
              <img
                src={Step3}
                alt="Project"
                className="img-fluid rounded mx-auto d-block"
              />
            </div>
          </div> */}

          {/* about code is commented for style references */}

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="company-description">
              <p className="desc-2">Draft Creation</p>
              <p className="desc-3">
                User Can Create drafts and post later. Share your ideas at the
                perfect time.
              </p>
            </div>

            <div className="project-image-holder">
              <img
                src={Step3}
                alt="Project"
                className="img-fluid rounded mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWork;
