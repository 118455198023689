/* eslint-disable */
import React, { useState } from "react";
import ProjectImage from "../../../assetsC/images/spread1.png";

const AboutHome = () => {
  const openChromeWebStore = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/spread/jdgafijlogmmfdhejidelkdddfdakeko?hl=en-GB",
      "_blank"
    );
  };

  return (
    <div className="about-area">
      <div className="container">
        <div className="row justify-center">
          <div className="about-heading"></div>
          <div className="about-footer-inner">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12 d-flex justify-content-center img-div">
                <img
                  src={ProjectImage}
                  alt="Project"
                  className="img-fluid"
                  width="100%"
                />
              </div>

              <div className="col-lg-6 col-sm-12 about-description ">
                <h1>
                  Effortlessly Manage Your X posts with Spread - Your Personal
                  AI Assistant!
                </h1>

                <h2>
                  Spread is your personal AI assistant for managing posts
                  effortlessly. With its powerful features, you can schedule
                  posts in advance, create drafts for perfecting your content,
                  and stay updated with top posts on topics that interest you.
                  Additionally, Spread's advanced AI technology enables you to
                  generate engaging posts aligned with your preferences. Install
                  the Spread Chrome extension today and streamline your tweet
                  management like never before.
                </h2>

                <div className="form-field">
                  <div className="aboutHomeDiv">
                    <button
                      className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
                      type="submit"
                      id="bodydownloadbutton"
                      onClick={() => openChromeWebStore()}
                    >
                      Add To Chrome
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
