/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../../../assetsC/images/logo.png";
import "./style.css";
import { Link } from "react-router-dom";

const Header = ({ scrollToFeature, scrollToPrice }) => {
  const openChromeWebStore = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/spread/jdgafijlogmmfdhejidelkdddfdakeko?hl=en-GB",
      "_blank"
    );
  };
  const [openMenu, setOpenMenu] = useState(false);
  const [isSidebarOpened, setSidebar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (width <= 768 && isSidebarOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [width, isSidebarOpened]);

  const activateSolutionDropdown = () => {
    setOpenMenu(true);
  };

  const hideSolutionDropdown = () => {
    setOpenMenu(false);
  };

  const scrollFunction = () => {
    scrollToFeature();
    // setSidebar(!isSidebarOpened);
    // document.body.style.overflow = "hidden";
  };

  const scrollPriceSection = () => {
    scrollToPrice();
    // setSidebar(!isSidebarOpened);
    // document.body.style.overflow = "hidden";
  };
  // const path = window.location.pathname;
  // let activeMenu;
  // if (path.includes("solutions")) {
  // activeMenu = "solutions";
  // }
  // if (path.includes("pricing")) {
  // activeMenu = "pricing";
  // }
  // if (path.includes("blog")) {
  // activeMenu = "blog";
  // }

  return (
    <header className="main-nav" style={{ backgroundColor: "#141417" }}>
      <div className="container headerContainer">
        <div className="headerAbout" onMouseLeave={hideSolutionDropdown}>
          <Navbar className="navabrClass" expand="md" variant="dark">
            <div className="logo navbarLogoHead">
              <a href="/">
                <img src={logo} alt="logo" width="auto" height="auto" />
              </a>
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="custom-toggler"
              onClick={() => activateSolutionDropdown()}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="basic-navbar-nav">
              <div className="ml-auto navbar-wrapper">
                <ul className="navbar-nav ml-auto">
                  <li className="menu-option">
                    <div className="menu" onMouseOver={hideSolutionDropdown}>
                      <Link to="/" style={{ color: "#ccc" }}>
                        Home
                      </Link>
                      {/* <a href="/">Home</a> */}
                    </div>
                  </li>

                  <li className="menu-option">
                    <div
                      // className={"menu "+ (activeMenu === "blog"?'active':'')}
                      onMouseOver={hideSolutionDropdown}
                    >
                      <span
                        // onClick={() => scrollFunction()}
                        onClick={() => {
                          scrollFunction();
                          hideSolutionDropdown(); // Close the menu
                        }}
                        style={{
                          fontFamily: "Neue Haas Unica",
                          cursor: "pointer",
                          color: "#ccc",
                        }}
                      >
                        Features
                      </span>
                    </div>
                  </li>
                  <li className="menu-option">
                    <div
                      // className={"menu "+ (activeMenu === "blog"?'active':'')}
                      onMouseOver={hideSolutionDropdown}
                    >
                      <span
                        // onClick={() => scrollFunction()}
                        onClick={() => {
                          scrollPriceSection();
                          hideSolutionDropdown(); // Close the menu
                        }}
                        style={{
                          fontFamily: "Neue Haas Unica",
                          cursor: "pointer",
                          color: "#ccc",
                        }}
                      >
                        Pricing
                      </span>
                    </div>
                  </li>
                  <li
                    className={"nav-item-invite-display"}
                    style={{ padding: "0px" }}
                  >
                    <button
                      onClick={() => openChromeWebStore()}
                      id="headerdownloadbutton"
                      className="btn-secondary-color add-to-chrome-btn"
                      type="button"
                    >
                      Add To Chrome
                    </button>
                  </li>
                </ul>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
