/* eslint-disable */
import React from "react";
import Logo from "../../../assetsC/images/logo.png";
import "./style.css";
const Footer = () => {
  return (
    <div className="main-nav main-footer pt-4">
      <div className="container">
        {/* <div className="nav-wrapper about"> */}
        <div className="row">
          <div
            className="logo col-sm-12 col-lg-4"
            style={{ marginLeft: "-12px" }}
          >
            <a href="/">
              <img src={Logo} alt="logo" width="33%" height="auto" />
            </a>
          </div>

          <div className="footer-menu col-lg-4 col-sm-12">
            <div className="row">
              <div className="col-sm-6">
                <a href="#" className="footer-items">
                  Terms of Service
                </a>
                <a href="#" className="footer-items">
                  Privacy Policy
                </a>
              </div>
              <div className="col-sm-6">
                <a href="#" className="footer-items">
                  Security Policy
                </a>
                <a href="#" className="footer-items">
                  Contact Us
                </a>
              </div>
            </div>
          </div>

          <div className="navbar-items col-lg-4 col-sm-12">
            <div className="btn-standard footer-items" href="/">
              © 2023 Spread. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
