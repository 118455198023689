import React, { Component } from "react";
import { Header, Footer } from "../layouts/home/index";

class Layout extends Component {  

  render() {
    const { children } = this.props;

    return (
      <div>        
        <Header />
        {children}
        <Footer />
      </div>
    );
  }
}

export default Layout;
