import Layout from "./components/pages/Layout";

function App() {
  return (    
    <>      
        <Layout />        
    </>
  );
}

export default App;
