import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import Home from './components/pages/Home';
import ThankYouPage from './components/pages/ThankYouPage';
import ErrorPage from './components/pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/thankyou",
    element: <ThankYouPage />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <React.StrictMode>      
      <RouterProvider router={router} >
        <App />
        </RouterProvider>      
    </React.StrictMode>
);

reportWebVitals();
