// /* eslint-disable */
import React, { Component } from "react";
import logo from "../../assetsC/images/logo.png";
import gify from "../../assetsC/images/half-browser.gif";
class ThankYouPage extends Component {
  render() {
    return (
      <div className="centered-page">
        <img src={logo} alt="Spread Logo" className="centered-logo-xl" />
        <h1 className="centered-h1">🚀 Thank you for subscribing to Spread</h1>
        <div className="centered-text">
          <h2 className="centered-h2">We're thrilled to have you on board.</h2>
          <p className="centered-paragraph">
            Get ready to supercharge your X posts game with our powerful
            features.
          </p>
          <img src={gify} alt="Get Extension Open" className="centered-gif" />
        </div>
      </div>
    );
  }
}

export default ThankYouPage;
