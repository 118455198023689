import React, { Component, createRef } from "react";
import {
  AboutHome,
  AboutWork,
  AboutFooter,
  Download,
  Header,
  Footer,
} from "../layouts/home/index";
// import Pricing from "../layouts/home/AboutWork";
import PricingPage from "../layouts/PricingPage";

class Home extends Component {
   scrollDiv = createRef();
    scrollDivPrice = createRef();
    scrollSmoothHandler = () => {
    this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };
  scrollSmoothPriceHandler = () => {
    this.scrollDivPrice.current.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <div>
      <Header scrollToFeature={this.scrollSmoothHandler}
      scrollToPrice={this.scrollSmoothPriceHandler} />
        <AboutHome />
        <AboutWork scrollDiv={this.scrollDiv} />
        <PricingPage scrollDivPrice={this.scrollDivPrice} />        
        <AboutFooter />
        <Download />
        <Footer/>
      </div>
    );
  }
}

export default Home;
