// eslint-disable-next-line
import React from "react";
import icon from "../../assetsC/images/checkMark.png";

const PricingPage = ({ scrollDivPrice }) => {
  const iconTextData = [
    { icon: icon, text: "posts Scheduling" },
    { icon: icon, text: "Draft Creation" },
    { icon: icon, text: "Stay Updated" },
    { icon: icon, text: "AI-Powered Content" },
    { icon: icon, text: "Chrome Extension" },
  ];

  const openChromeWebStore = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/spread/jdgafijlogmmfdhejidelkdddfdakeko?hl=en-GB",
      "_blank"
    );
  };

  return (
    <div className="about-footer-price" ref={scrollDivPrice}>
      <div className="container users-quote-pricing">
        <h1>Save money with Spread 🎉</h1>
        <p className="title-text-header">
          Unlock the Power of Spread - Your Personal posts Assistant!
        </p>

        <div className="project-snap-row">
          <div>
            <div className="price-cards-container">
              <div className="price-card">
                <h2 className="title-text">Monthly Plan</h2>
                <p className="p-price-title">Save big by paying annually!</p>
                <p className="p-price-amount">$9.99/month</p>
                {/* <div>
                  <p className="p-price-amount">$6.99/month</p>
                  <p>$83.99/yearly</p>
                </div> */}
                <div>
                  <p>($120/yearly)</p>
                </div>
                <p className="p-price">
                  All the basics for businesses that are just getting started.
                </p>
                <div>
                  <div className="line"></div>
                  <div className="feature-items">
                    <div className="feature-list">
                      {iconTextData.map((item, index) => (
                        <div key={index} className="icon-text-container">
                          <span className="icon">
                            <img src={item.icon} alt="Icon" />
                          </span>
                          <span className="text-price-page">{item.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div class="pt-4">
                    <button
                      // class="bg-indigo-500 text-white hover:bg-indigo-700 w-full text-md rounded-sm text-center py-2 capitalize border-none	"
                      className="w-full bg-indigo-500 text-white hover:bg-indigo-700  text-black text-md rounded-sm text-center py-2 capitalize border-none"
                      style={{ borderStyle: "none" }}
                      onClick={() => openChromeWebStore()}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div className="price-card">
                <h2 className="title-text">Yearly Plan</h2>
                <div>
                  <p>
                    {/* <span className="p-discount">$119/yearly</span> */}
                    <span className="p-price-title">
                      🎉 whooo! you are saving
                    </span>
                    <span className="p-price-off"> 30% 🎉 </span>
                  </p>
                </div>
                <p className="p-price-amount">$6.99/month</p>
                <div>
                  <p>($84/yearly)</p>
                </div>
                <p className="p-price">
                  All the basics for businesses that are just getting started.
                </p>
                <div>
                  <div className="line"></div>
                  <div className="feature-items">
                    <div className="feature-list">
                      {iconTextData.map((item, index) => (
                        <div key={index} className="icon-text-container">
                          <span className="icon">
                            <img src={item.icon} alt="Icon" />
                          </span>
                          <span className="text-price-page">{item.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="pt-4">
                    <button
                      // bg-indigo-500 text-white hover:bg-indigo-700
                      className="w-full  bg-indigo-500 text-white hover:bg-indigo-700  text-md rounded-sm text-center py-2 capitalize border-none"
                      style={{
                        // backgroundColor: "#fff",
                        // color: "#000",
                        borderStyle: "none",
                      }}
                      onClick={() => openChromeWebStore()}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className="title-text-header">
              Subscribe to Spread and supercharge your X experience! Install the
              Spread Chrome extension today and revolutionize your posts
              management. Don't miss out on effortless posts perfection.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
