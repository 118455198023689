import React, { Component } from 'react';
import logo from "../../assetsC/images/logo.png";

class ErrorPage extends Component {
  render () {
    return (
      <div className="centered-page">              
        <img src={logo} alt="Spread Logo" className="centered-logo-xl" />
        <h1 className="centered-h1">❗️Payment Failed❗️</h1>
        <div className="centered-text">
          <h2 className="centered-h2">
          Your payment could not be processed.
          </h2>
          <p className="centered-paragraph">
          Please check your payment details and try again.
          </p>
          <button
              className="btn-primary-color my-4 my-sm-4 HomeShowBtn"
              type="submit"
              id="bodydownloadbutton"              
          >
              Retry Payment
          </button>
        </div>
      </div>
    );
  };
  }

export default ErrorPage;